.main-menu{
    width: 100%;
	position: fixed;
	top: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
	background: rgba(0,0,0, 0.4);
	opacity: 0;
	visibility: hidden;
	transition: all ease 0.3s;
    z-index: 999;
    .content{
		width: 240px;
		height: 100%;
		position: relative;
		background: $cor;
		padding: 30px;
		box-sizing: border-box;
		border-left: solid 1px #333;
		right: -240px;
        transition: all ease 0.3s;
        box-shadow: -8px 0px 20px rgba(41, 45, 65, 0.2);
    }
    .icon-menu{
        float: right;
    }
    .user{
        text-align: center;
        margin-bottom: 40px;
        padding-top: 40px;
        img{
            margin-bottom: 20px;
        }
        p{
            color: #fff;
            font-size: 14px;
            
            small{
                font-size: 12px;
                color: #8B8D97;
            }
        }
    }
    nav{
        margin-bottom: 40px;
        min-width: 150px;
        a{
            display: block;
            font-size: 14px;
            color: #fff;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: solid 1px #4C5062;
            transition: all ease 0.3s;
            text-align: left;
            &:last-child{
                border-bottom: none;
            }
            &:hover, &.router-link-exact-active, &.router-link-active{
                color: #3498DB;
            }
        }
    }
    .logout{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px 0;
        transition: all ease 0.3s;
        p{
            color: #fff;
            transition: all ease 0.3s;
            font-size: 14px;
        }
        &:hover{
            background: #393e58;
            padding: 10px;
            p{
                color: #f39c12;
            }
            
        }
    }
	&.ativo{
		opacity: 1;
		visibility: visible;
		.content{
			right: 0;
		}
	}
}